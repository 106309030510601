import Error from "@@/common/Error.vue";
import cont_match_reg from "@/utils/editor.js";
import { getHistoryIdeaContent } from "@api/index";
export default {
  components: {
    Error
  },
  data() {
    return {
      showError: false,
      showMessage: "",
      content: ''
    };
  },
  async mounted() {
    const idea_id = this.$route.params.id ? this.$route.params.id.replace('.html', '') : '';
    const {
      data,
      status,
      message
    } = await getHistoryIdeaContent({
      idea_id: idea_id
    });
    if (status == 0) {
      this.content = data ? data.content : '';
    } else if (status == '999999') {
      this.showError = true;
      this.showMessage = "网络错误";
      this.sensors.track('IdeaClientDetail', {
        Type: "网络错误"
      });
    } else {
      this.showError = true;
      this.showMessage = message || "什么都没有哦";
      this.sensors.track('IdeaClientDetail', {
        Type: "接口错误",
        Message: message
      });
    }
    const _this = this;
    _this.$nextTick(() => {
      if (!_this.showError && _this.$refs.editorBox && _this.content) {
        _this.$refs.editorBox.appendChild(cont_match_reg(_this.content));
      }
    });
  }
};
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-0374ae2f"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  ref: "editorBox"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_Error = _resolveComponent("Error");
  return _openBlock(), _createElementBlock("div", null, [_createElementVNode("div", _hoisted_1, null, 512), $data.showError ? (_openBlock(), _createBlock(_component_Error, {
    key: 0,
    message: $data.showMessage
  }, null, 8, ["message"])) : _createCommentVNode("", true)]);
}